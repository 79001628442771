
  @media (max-width: 768px) {
    /* Make table cells stack on small screens */
    .rbc-agenda-view table thead tr {
      width: 100%;
      /* margin-bottom: 10px; */
    }
    .rbc-agenda-view table thead {
        width: 100%;
        /* margin-bottom: 10px; */
      }
    .rbc-agenda-view table {
        width: 100%;
        /* margin-bottom: 10px; */
      }
    /* .rbc-agenda-view table tbody tr {
        margin-bottom: 10px; 
      } */
    
    /* .rbc-agenda-view table thead .rbc-header, .rbc-agenda-time-cell, .rbc-agenda-event-cell {
      display: none;
    }
  
    .rbc-agenda-date-cell, .rbc-agenda-time-cell {
      width: auto;
      display: block;
    }
  
    .rbc-agenda-content .rbc-agenda-date-cell, .rbc-agenda-content .rbc-agenda-time-cell {
      padding-right: 10px;
      padding-left: 10px;
    } */
  }
  